/***************************************************
  Media queries for responsive design
****************************************************/

@import "breakpoint-max";

@media (min-width: $media-breakpoint-md) {
  @import "breakpoints/md/overlay-dropdown-plus";
  @import "breakpoints/md/overlay-menu";
  @import "breakpoints/md/menu-icons";
}

@media (min-width: $media-breakpoint-lg) {
  @import "breakpoints/lg/overlay-dropdown-plus";
  @import "breakpoints/lg/overlay-menu";
}
